require('../../scss/pages/initial/landing-initial.scss');

import '../partials/general.js';

window.onload = function() {
    import('../../scss/pages/rest/landing-rest.scss')
    .catch(error => 'An error occurred while loading the google maps component');
}

$(document).on('submit', '.missleads-form', function(e){
    e.preventDefault();

    var modal = $('#leadResponseModal');

    var path = $(this).attr('action');
    var data = $(this).serializeArray();

    $.ajax({
        type: "POST",
        url: path,
        dataType: "json",
        data: data,
        success: function success(response) {
            $(modal).find('.modal-body').empty();
            $(modal).find('.modal-body').html(response.message);
            $(modal).modal('show');
        },
        error: function error(response) {
            $(modal).find('.modal-body').empty();
            $(modal).find('.modal-body').html(response.responseJSON.message);
            $(modal).modal('show');
        },
    });

});