import '../partials/jquery-global.js';

require('bootstrap');

// const yall = require('yall-js');
import yall from 'yall-js';

document.addEventListener("DOMContentLoaded", function(event) {
    yall({
        observeChanges: true
    });

    var navbar = document.getElementById("header-navbar");
    
    window.onscroll = function() { scrollEvent() };

    $('#scrollUp').click( function(e) {
        e.preventDefault();
        $('html, body').animate( {scrollTop : 0}, 800 );     
    });
    
    function scrollEvent() {
        if (window.pageYOffset > 0) {
            // Adding navbar effect
            navbar.classList.add("navbar-sticky")

            // Showing the scroll arrow
            $('#scrollUp').fadeIn();   
        } else {
            // Removing navbar effect
            navbar.classList.remove("navbar-sticky");

            // Hidding the scroll arrow
            $('#scrollUp').fadeOut();   
        }
    }

});